import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, initializeFirestore, doc, getDoc, query, collection, where, getDocs, setDoc, deleteDoc, orderBy, WhereFilterOp } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAuX85nCsiV8PE_heajHjC6SNScwS2RaXU",
  authDomain: "my-blog-33eb9.firebaseapp.com",
  projectId: "my-blog-33eb9",
  storageBucket: "my-blog-33eb9.appspot.com",
  messagingSenderId: "705509228809",
  appId: "1:705509228809:web:91ef4d3dd825236a79d3d8",
  measurementId: "G-ZSZR9GE7W0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LfqXkEpAAAAAJUlTXKaen6ewt4NjpXdPckXmbj_'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: false
});

const analytics = getAnalytics(app);

const db = initializeFirestore(app, {
      experimentalForceLongPolling: false,
    });

const getPosts = async (callback, collection_)=>{

	const q = query(collection(db, collection_), orderBy("lastModifyDt"));

	const querySnapshot = await getDocs(q);
	const data = [];
	querySnapshot.forEach((doc) => {
		const d = doc.data();
		console.log(d);
		data.unshift(d);
	});

        callback(data);

        


    };

export {getPosts};