import './App.css';
function Menu(props) {

    const resetPage = props.resetLoad;

    return (
        props.loaded?
        <div>
        <div className="header no-print">
          <div className="left" style={{fontSize:"0.9rem"}}>&nbsp;</div>
          <div className="content">The Jungle Work</div>
          <div className="right">&nbsp;</div>
      </div>
      <div className="menu no-print">
          <div className="left" style={{fontSize:"0.9rem"}}>&nbsp;</div>
          <div className="content"><a href={"/blogs"} onClick={resetPage}>Blog</a>
          &nbsp;&nbsp;&nbsp;
          <a href={"/recipes"} onClick={resetPage}>Recipes</a>
	  </div>
          <div className="right"><a href={"#"}>About</a></div>
      </div>
      <div className="no-print" style={{
        paddingTop:"15vh",
        display: "flex",
        justifyContent: "center",
        flexDirection: "row"
      }}>
          <div className="left" style={{fontSize:"0.9rem"}}>&nbsp;</div>
          <div className="content">Work can be like a jungle. Through our experiences we learn how to navigate life. This blog is a collection of annecdotes, and experiences for doing just that.</div>
          <div className="right">&nbsp;</div>
      </div>
      </div>:""
      );
    }

export default Menu;