import './App.css';
import { 
  useState, 
  useEffect,
  //useCallback, 
  useMemo,
  useReducer } from "react";
import parse from 'html-react-parser';
import {getPosts} from "./firebaseConfig";
const getRecipes = getPosts;
function Recipes(props) {

    const [posts,setPosts] = useState([]);

	useEffect(()=>{
		getRecipes((data)=>{
			const d = data;
			setPosts((prev)=>{
				return d;
			});
      props.setLoaded(prev=>true);
		},"recipes");
	},[]

	);

  let dLinks = posts.map((v,i)=>{
		return <div key={"pl"+i} style={{paddingTop:"1rem",paddingBottom:"1rem"}}><div style={{fontSize:"1rem"}}><a href={"#p"+i}>{v.title}</a></div></div>
	});

	let dPosts = posts.map((v,i)=>{
    let mdt = new Date(v.lastModifyDt.seconds * 1000);
    let fdt = mdt.getFullYear()+"/"+(mdt.getMonth()+1)+"/"+mdt.getDate();
    let sn = 0;
		return <div key={"p"+i} id={"p"+i} style={{paddingTop:"1rem",paddingBottom:"1rem"}}><div style={{fontSize:"1rem"}}>{v.title}</div><div style={{fontSize:"0.60rem",lineHeight:"2rem"}}>Last modified on {fdt}.</div><div style={{fontSize:"0.8rem",lineHeight:"1rem"}}>{parse(v.intro)}</div><div style={{fontSize:"0.8rem",lineHeight:"1rem"}}>&nbsp;</div><div style={{fontSize:"0.8rem",lineHeight:"1rem"}}>{(v.post.map((step,k)=>{
      if(!step || step.indexOf("data:") != 0) {
        sn += 1;
      }
      return <div key={"psub"+k}>{step && step.indexOf("data:") == 0 ? <img src={step} width={300} /> : parse(step)}<div>&nbsp;</div></div>
    }))}</div></div>
	});

  return (
    props.loaded?
    <div className="App-section">
        <div className="container">
            <div className="left">&nbsp;</div>
            <div className="content">{dLinks}<div>&nbsp;</div>{dPosts}</div>
            <div className="right">&nbsp;</div>
        </div>
    </div>:<div>Loading...</div>
  );
}

export default Recipes;


