import './App.css';
import { 
  useState, 
  useEffect,
  //useCallback, 
  useMemo,
  useReducer } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Recipes from "./Recipes";
import Blogs from "./Blogs";
import Testercise from "./Testercise";
import Menu from "./Menu";
import Footer from "./Footer";


function App() {

  const [loaded,setLoaded] = useState(false);

  const resetLoad = (e)=>{
    setLoaded(prev=>false);
  };

  return (
    <div className="App">
      <Menu resetLoad={resetLoad} loaded={loaded} />
      <div className="App-content">
      <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Blogs setLoaded={setLoaded} loaded={loaded} />} />
          <Route path="blogs" element={<Blogs setLoaded={setLoaded} loaded={loaded} />} />
          <Route path="recipes" element={<Recipes setLoaded={setLoaded} loaded={loaded} />} />
          <Route path="*" element={<div>Page not found!</div>} />
        </Route>
      </Routes>
    </BrowserRouter>
    
    <Footer loaded={loaded} />
      </div>
    </div>
  );
}

export default App;
