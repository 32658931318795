import './App.css';
import { 
  useState, 
  useEffect,
  //useCallback, 
  useMemo,
  useReducer } from "react";
import parse from 'html-react-parser';
import {getPosts} from "./firebaseConfig";
const getBlogs = getPosts;
function Blogs(props) {

    const [posts,setPosts] = useState([]);

	useEffect(()=>{
		getBlogs((data)=>{
			const d = data;
			setPosts((prev)=>{
				return d;
			});
      props.setLoaded(prev=>true);
		},"posts");
	},[]

	);

	let dPosts = posts.map((v,i)=>{
    let mdt = new Date(v.lastModifyDt.seconds * 1000);
    let fdt = mdt.getFullYear()+"/"+(mdt.getMonth()+1)+"/"+mdt.getDate(); 
		return <div key={"p"+i} style={{paddingTop:"1rem",paddingBottom:"1rem"}}><div style={{fontSize:"1rem"}}>{v.title}</div><div style={{fontSize:"0.60rem",lineHeight:"2rem"}}>Last modified on {fdt}.</div><div style={{fontSize:"0.8rem",lineHeight:"1rem"}}>{parse(v.post)}</div></div>
	});

  return (
    props.loaded?
    <div className="App-section">
        <div className="container">
            <div className="left">&nbsp;</div>
            <div className="content">{dPosts}</div>
            <div className="right">&nbsp;</div>
        </div>
    </div>:<div>Loading...</div>
  );
}

export default Blogs;


